import request from "@/utils/request";

export function deploy(data) {
    return request({
        url: "/deploy",
        method: "post",
        data,
    });
}
export function restore(data) {
    return request({
        url: "/restore",
        method: "post",
        data,
    });
}
