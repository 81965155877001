<template>
  <v-container fluid style="height: calc(100vh - 60px); overflow-y: auto">
    <v-row>
      <v-col offset="3" cols="6">
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-autocomplete
                :items="lecturers"
                v-model="form.lecturerId"
                item-text="name"
                item-value="id"
                label="Giảng viên"
                clearable
              ></v-autocomplete>
              <v-select
                :items="filteredExaminations"
                v-model="form.examinationIds"
                item-text="name"
                item-value="id"
                label="Kíp thi"
                multiple
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-select>

              <v-text-field
                v-model="form.environment.host"
                label="Host"
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-text-field>
              <v-text-field
                v-model="form.environment.port"
                label="Port"
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-text-field>
              <v-text-field
                v-model="form.environment.user"
                label="User"
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-text-field>
              <v-text-field
                v-model="form.environment.password"
                label="Password"
                type="password"
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-text-field>
              <v-text-field
                v-model="form.environment.database"
                label="Database"
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-text-field>
              <v-select
                :items="['deploy', 'restore']"
                v-model="action"
                label="Hành động"
                :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              ></v-select>
              <v-btn @click="showDialog" class="d-block mx-auto" color="green"
                >Thực thi</v-btn
              >
            </v-form></v-card-text
          >
        </v-card>
        <v-dialog v-model="dialog" max-width="400px">
          <v-card>
            <v-card-title class="h5 font-weight-bold">
              Xác nhận
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="confirmText"
                :label="`Nhập chữ ${action} để đồng ý làm mới`"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false"> Hủy </v-btn>
              <v-btn
                @click="execute"
                color="error"
                :disabled="confirmText !== action"
              >
                Thực thi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row></v-container
  >
</template>
<script>
import { deploy, restore } from "@/api/deploy";
import { getExaminations } from "@/api/original-exam";
import { index as getLecturers } from "@/api/lecturer";
export default {
  data() {
    return {
      subjects: [],
      examinations: [],
      valid: true,
      action: null,
      confirmText: "",
      lecturers: [],
      dialog: false,
      filteredExaminations: [],
      form: {
        lecturerId: null,
        examinationIds: [],
        environment: {
          host: "",
          port: "5432",
          user: "postgres",
          password: "",
          database: ""
        }
      }
    };
  },
  watch: {
    dialog(val) {
      if (!val) this.confirmText = "";
    },
    "form.lecturerId"(val) {
      this.form.examinationId = null;
      this.filteredExaminations = this.examinations.filter(
        item => item.ownerId == (val || null)
      );
    }
  },
  methods: {
    async showDialog() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      this.dialog = true;
    },
    async getExaminations() {
      const { data } = await getExaminations({ all: true });
      this.examinations = data;
      this.filteredExaminations = this.examinations.filter(
        item => item.ownerId === null
      );
    },
    async getLecturers() {
      const { data } = await getLecturers();
      this.lecturers = data;
    },
    async execute() {
      this.dialog = false;
      this.$loader(true);

      try {
        const action = this.action === "deploy" ? deploy : restore;
        await action(this.form);
        this.$snackbar("Thực thi thành công!", "success");
      } catch (error) {
        console.log(error);
        // this.$snackbar("Đã có lỗi xảy ra. Hãy kiểm tra lại", "error");
      } finally {
        this.$loader(false);
      }
    }
  },
  created() {
    this.getExaminations();
    this.getLecturers();
    this.form.environment.host = process.env.VUE_APP_CLOUD_DATABASE_HOST || "";
    this.form.environment.database =
      process.env.VUE_APP_CLOUD_DATABASE_NAME || "";
  }
};
</script>
